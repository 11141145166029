var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-text', [_c('v-row', _vm._l(_vm.icons, function (icon) {
    return _c('v-col', {
      key: icon,
      attrs: {
        "cols": "1"
      }
    }, [_c('v-icon', {
      attrs: {
        "title": icon,
        "x-large": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$copy(icon);
        }
      }
    }, [_vm._v(_vm._s(icon))])], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }