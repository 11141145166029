<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col v-for="icon in icons" :key="icon" cols="1">
          <v-icon :title="icon" x-large @click="$copy(icon)">{{ icon }}</v-icon>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash';
import * as icons from '@tutti/plugins/mdi.plugin';

export default {
  data() {
    return {
      icons: Object.keys(icons).map(icon => _.kebabCase(icon)),
    };
  },
};
</script>
